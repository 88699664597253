.meeting-view-auth{
  height: 100vh;
  background-image: url("../../../assets/loginBackground.png");
  background-size: cover;
}

.meeting-view-auth-header {
  padding: 20px 50px!important; 
  background-color: transparent;
  border-color: var(--theme-white) !important;
  /* box-shadow: 0 12px 18px 0 rgb(0 0 0 / 10%) !important; */
}

.meeting-view-auth-header h2{
  margin-top: 5px !important;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-20);
  color: black;
  letter-spacing: -0.02em;
  margin-left: -8px;
}

.meeting-view-auth-header ul{
  display: flex;
  justify-content: flex-start !important;
}

.meeting-view-auth-header ul li {
  display: inline;
  color: #000000;
  position: relative;
  top: 3px;
  padding: 5px 15px;
}

.meeting-view-auth-header ul li a {
  color: #000000;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
}

.meeting-view-auth-header img {
  width: 40px;
  margin-top: -3px;
}

.meeting-view-auth-header p{
  margin-top: 10px !important;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-13);
  color: black;
  letter-spacing: -0.02em;
  margin-left: -8px;
}

.meeting-view-auth-header p a{
  font-size: var(--font-size-14);
  color:  var(--theme-color);
  margin-left: 5px;
}

.txt-right{
  text-align: right;
}


@media only screen and (max-width: 600px) {
  .meeting-view-auth-header p{
    margin-top: 20px !important;
  }
}
