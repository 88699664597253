:root {
    --theme-color: #D0824F;
    --theme-border-color: #D0824F;
    --theme-light-color: #C57334;
    --theme-white: #ffffff;


    --font-size-10: 10px;
    --font-size-11: 11px;
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-17: 17px;
    --font-size-18: 18px;
    --font-size-19: 19px;
    --font-size-20: 20px;
    --font-size-21: 21px;
    --font-size-22: 22px;
    --font-size-23: 23px;
    --font-size-24: 24px;
    --font-size-25: 25px;
    --font-size-26: 26px;
    --font-size-27: 27px;
    --font-size-28: 28px;
    --font-size-29: 29px;
    --font-size-30: 30px;
    --font-size-31: 31px;
    --font-size-32: 32px;
    --font-size-33: 33px;
    --font-size-34: 34px;
    --font-size-35: 35px;
    --font-size-36: 36px;
    --font-size-37: 37px;
    --font-size-38: 38px;
    --font-size-39: 39px;
    --font-size-40: 40px;
    --font-size-41: 41px;
    --font-size-42: 42px;
    --font-size-43: 43px;
    --font-size-44: 44px;
    --font-size-45: 45px;
    --font-size-46: 46px;
    --font-size-47: 47px;
    --font-size-48: 48px;
    --font-size-49: 49px;
    --font-size-50: 50px;
    --font-size-51: 51px;
    --font-size-52: 52px;
    --font-size-53: 53px;
    --font-size-54: 54px;
    --font-size-55: 55px;
    --font-size-56: 56px;
    --font-size-57: 57px;
    --font-size-58: 58px;
    --font-size-59: 59px;
    --font-size-60: 60px;

    --font-weight-100 : 100;
    --font-weight-200 : 200;
    --font-weight-300 : 300;
    --font-weight-400 : 400;
    --font-weight-500 : 500;
    --font-weight-600 : 600;
    --font-weight-700 : 700;
    --font-weight-800 : 800;
    --font-weight-900 : 900;
}