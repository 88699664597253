.meeting-view{
    background-color: #f0f0f0;
}

.meeting-view-header {
  /* background-color: var(--theme-white);
  padding: 10px 150px;
  position: sticky !important;
  top: 0;
  z-index: 99; */
  background-color: #f0f0f0;
    padding: 10px 150px;
    position: sticky !important;
    top: 0;
    z-index: 99;
}

.meeting-view-header-main {
  display: flex;
  justify-content: center;
  align-items: center;

}

.meeting-view-header ul {
  margin-top: 18px;
  text-align: center;

}

.meeting-view-header ul li {
  list-style: none;
  display: inline;
  margin-right: 20px;
  cursor: pointer;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
  color: #000000;
}

.meeting-view-header .header-links {
  text-align: start;
}

.meeting-view-header h2 {
  margin-top: 13px !important;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-20);
  color: black !important;
  letter-spacing: -0.02em;
  margin-left: -8px;
}

h2 a,
h2 a:hover {
  color: black;
}

.meeting-view-header .logo-img {
  width: 40px;
  margin-top: 6px;
}

.meeting-view-header .txt-right {
  text-align: right !important;
}

.meeting-view-header .header-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.meeting-view-header ul li a {
  margin-top: 3px;
  color: black;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
}

.meeting-view-header .header-btns a {
  margin-top: 3px;
  color: black;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
}

.meeting-view-header Button,
.meeting-view-header Button:hover {
  background-color: var(--theme-color);
  border-radius: 10px;
  height: 40px;
  color: var(--theme-white);
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-600);
  padding: 18px, 22px, 18px, 22px !important;
  border: 1px solid var(--theme-border-color);
  ;
}

.meeting-view-header .toggle-mobile {
  font-size: 35px;
  margin-top: 20px;
}


.meeting-view-header-mobile .proj-title {
  margin-top: -10px !important;
}

.meeting-view-header-mobile h2 {
  margin-top: 13px !important;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-20);
  color: black;
  letter-spacing: -0.02em;
}

.meeting-view-header-mobile ul {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.meeting-view-header-mobile ul li {
  padding-bottom: 15px;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
  text-align: center;
}

.meeting-view-header-mobile ul li a {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-600);
  color: black;
}

.meeting-view-header-mobile ul li a Button,
.meeting-view-header-mobile ul li a Button:hover {
  background-color: var(--theme-color);
  border-radius: 10px;
  height: 40px;
  color: var(--theme-white);
  font-size: 14px;
  font-weight: 600;
  padding: 20px, 22px, 20px, 22px !important;
  border: 1px solid var(--theme-border-color);
}

.meeting-view-header-mobile .logo-img-mob {
  width: 40px;
  margin-top: 7px;
}

.login-btn {
  position: relative;
  top: -3px;
}

.service-mini-footer {
  padding: 20px;
  padding-top: 40px;
}

.service-mini-footer h1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.service-mini-footer h1 span {
  font-size: 14px;
  color: #5ba7a9;
}

.meeting-view-footer {
  margin: 50px 150px;
  padding-bottom: 50px;
}

.meeting-view-footer h2 {
  color: black;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
  margin-bottom: 10px;
  margin-top: 30px;

}

.max-foot h3 {
  color: #656565;
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
}

.max-foot h3:hover {
  text-decoration: underline;
  cursor: pointer;
}


.max-foot h3 a {
  color: #656565;
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
}

.max-foot h3 a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.subscribe-footer {
  margin-top: 30px;
  background-color: #d3c8b9;
  border-radius: 20px;
  padding: 30px;
}

.subscribe-footer h1 {
  margin-top: -5px;
  font-family: Poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
  letter-spacing: -0.02em;
  color: black;
}

.subscribe-footer h2 {
  margin-top: 15px;
  font-size: 1.15em;
  color: black;
  font-weight: 400;
}

.subscribe-footer .footer_input,
.footer_input:hover {
  width: 100%;
  height: 40px !important;
  padding: 10px 35px;
  background: rgb(240, 236, 236);
  border-radius: 12px !important;
  margin: 15px 0px 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  color: black;
  border: none;
}

.subscribe-footer input::placeholder {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.711);
}

.subscribe-footer .ant-btn,
.subscribe-footer .ant-btn:hover {
  width: 100%;
  height: 40px;
  background: var(--theme-light-color);
  font-family: 'Poppins';
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  border: none;
  cursor: pointer;
}

.subscribe-footer .ant-form-item-explain-error {
  margin-top: -5px !important;
  color: black;
}



@media only screen and (max-width: 940px) {

  .meeting-view-header {
    padding: 10px 50px;
  }

  .meeting-view-footer {
    margin: 50px 50px;
  }

  .meeting-view-header .header-btns {
    display: none;
  }

}

@media only screen and (max-width: 600px) {
  .meeting-view-header {
    padding: 10px 20px !important;
  }

  .meeting-view-footer {
    margin-left: 30px;
    margin-right: 15px;
    padding-bottom: 50px !important
  }

  .meeting-view-header h2 {
    text-align: center !important;
    background-color: #3157F6 !important;
  }

  .subscribe-footer {
    margin-top: 40px;
    background-color: #e7aa54;
    border-radius: 40px;
    padding: 25px;
  }

  .meeting-view-header .header-btns {
    display: none !important;
  }

  .meeting-view-header .toggle-mobile {
    margin-top: 5px !important;
    font-size: 30px;
  }
}

input {
  font-size: 12px !important;
  height: 35px !important;
}