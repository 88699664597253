html,
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: var(--theme-white);
  color: #121212;
  font-size: 14px;
}

body {
  overflow-x: hidden !important;
}

.txt-center {
  text-align: center;
  width: 100%;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.loader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader img {
  width: 20%;
}

.meeting-view {
  height: auto;
}

.ant-form-item-explain-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: red;
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  border-color: red;
  box-shadow: none;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border-color: red;
  box-shadow: none;
}

.ant-input,
.ant-input:hover,
.ant-input:focus {
  border-radius: 5px;
  box-shadow: none;
  border-color: var(--theme-border-color);
}

.ant-input-group-addon:first-child {
  border-color: var(--theme-border-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-radius: 5px;
  box-shadow: none;
  padding: 0px 11px !important;
  border-color: var(--theme-border-color) !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: inline-block !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 15px;
  font-family: "Poppins";
  line-height: 1;
  content: "*";
}

.ant-form-item-label>label::after {
  content: "" !important;
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-modal-footer {
  display: none;
}

.square {
  font-size: 10px;
  position: relative;
  top: -5px;
}

.center-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
  width: 100%;
}

.center-data img{
  width: 20%;
}

.center{
  text-align: center;
}



/* scrollbar start*/

::-webkit-scrollbar {
  width: 3px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3157F6;
  border-radius: 10px;
}

/* scrollbar End*/

.ant-back-top {
  right: 0 !important;
  left: 60px !important;
}

.backTo_top button {
  background-color: var(--theme-light-color);
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
  cursor: pointer;
  font-weight: 500;
  height: 40px;
  width: 40px;
}

.backTo_top button img {
  width: 18px;
  height: 18px;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.meeting-view-admin-header {
  padding: 10px 0px;
}

/* .ant-form-item-label>label {
  gap: 5px !important;
  flex-direction: row-reverse !important;
  right: 5px !important;
} */
.ant-select, .ant-select-selector {
  border-radius: 5px !important;
  box-shadow: none !important;
  border: 1px solid var(--theme-border-color);
}

.ant-select-selector {
  border-radius: 5px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 2px 11px !important;
}
