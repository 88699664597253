.superAdmin-view {
    background-color: #F4F7FC;
    height: 100vh;
}

.meeting-view-superAdmin-header {
    padding: 0px 120px;
    background-color: white;
}

.meeting-view-superAdmin-header .logo-img {
    width: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.meeting-view-superAdmin-header h2 {
    margin-top: 16px !important;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-20);
    color: black !important;
    letter-spacing: -0.02em;
    margin-left: 8px;
}

.meeting-view-superAdmin-header ul li {
    list-style: none;
    display: inline;
    margin-right: 30px;
    font-size: 14px;
    cursor: pointer;
    color: black;
    font-weight: 500;
}

.meeting-view-superAdmin-header ul li .beta {
    font-size: 12px;
    background-color: var(--theme-color);
    padding: 3px 10px;
    border-radius: 50px;
    color: var(--theme-white);
    margin-left: 5px;
}

.meeting-view-superAdmin-header ul li .anticon {
    font-size: 16px;
    margin-right: 5px;
}

.meeting-view-superAdmin-header ul {
    position: relative;
    top: 20px
}

.logoutfield {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.logout1 {

    font-weight: 600;
    cursor: pointer;
}

.meeting-view-superAdmin-header ul li a {
    color: #000;
}