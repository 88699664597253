@media only screen and (max-width: 600px) {
  .ant-row {
    margin: 0px !important;
  }

  .logo-img {
    margin-top: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* banner start */

  .meet-view-banner {
    margin: 0px 15px !important;
  }


  .meet-view-banner h1 {
    font-size: 38px !important;
    line-height: 50px !important;
    margin-top: 0px !important;
  }

  .meet-view-banner h1 span {
    font-size: 38px !important;
    line-height: 50px !important;
    margin-top: 0px !important;
  }

  .meet-view-banner p {
    margin-bottom: 25px !important;
  }

  .banner-input {
    margin-top: 0px !important;
    width: 100% !important;
  }

  .meet-view-banner .banner-input .ant-input {
    margin-left: -4px !important;
  }

  .meet-view-banner .banner-input .ant-btn {
    margin-left: 5px !important;
  }

  .meet-view-banner .banner-image {
    display: none !important;
  }

  .meet-view-banner .banner-img {
    width: 100% !important;
    padding: 0px !important;
    margin-top: 40px;
  }

  /* banner end */

  .partner-class,
  .secure-class,
  .meet-view-advance-feature,
  .meet-view-tablet-minutes,
  .meet-view-testimonial,
  .meet-view-mail {
    margin: 20px 15px !important;
  }


  .meet-view-partner h1 {
    font-size: 11px !important;
  }

  .partner-class .partner-img {
    width: 100% !important;
    margin-top: -15px !important;
  }

  .partner-class .ids_logo {
    margin-top: 25px !important;
    margin-left: -50px;
    justify-content: center !important;
  }

  .partner-class {
    height: calc(25% - 20px) !important;
  }

  .secure-class {
    margin: 0px 10px !important;
  }

  .secure-class .secure-image {
    margin-top: -70px !important;
    margin-left: 0px !important;
    justify-content: flex-end !important;
  }

  .secure-class .secure-image .secure-img-box {
    width: 90% !important;
    height: 350px !important;
    border-radius: 35px;
    background-color: #E6E6E6 !important;
  }

  .secure-class .meet-view-secure h1{
    margin-bottom: 16px !important;
  }

  .meet-view-secure .secure-img {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .secure-class .secure-image-two {
    min-height: 450px !important;
    width: 100%;
    margin-top: -30px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .secure-class .secure-image-two .secure-img-sec-box {
    width: 90% !important;
    height: 360px !important;
  }

  .secure-class .meet-view-secure .ant-card {
    margin-bottom: 10px !important;
  }

  .secure-class .meet-view-secure .ant-card h3 {
    font-size: var(--font-size-17) !important;
  }

  .secure-class .meet-view-secure .ant-card p {
    font-size: var(--font-size-14) !important;
    margin-top: 4px !important;
  }

  .secure-class .sector-img-two-container {
    display: none !important;
  }

  .secure-img-two {
    width: 100% !important;
    margin-top: -10px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 40px !important;
  }

  .meet-view-advance-feature .advance-feature-img-container {
    margin-left: 0px !important;
  }

  .secure-class .meet-view-secure .secure-step-mob {
    margin-top: -60px !important;
  }

  .secure-class .secure-image-third {
    margin-top: -20px !important;
    margin-left: 0px !important;
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end !important;
  }

  .secure-class .secure-image-third .secure-img-box-third {
    width: 90% !important;
    height: 350px !important;
    border-radius: 35px;
    background-color: #E6E6E6 !important;
  }

  .secure-class .secure-img-third {
    width: 100% !important;
    height: 350px;
    margin-bottom: 20px;
    object-fit: cover;
    padding: 10px;
  }

  .secure-class .meet-view-secure {
    margin-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .secure-class .meet-view-secure h2 {
    margin-top: 0px !important;
  }

  .add-title,
  .add-titles {
    padding-top: 0px !important;
  }

  .clip-shape {
    display: none;
  }

  .meet-view-work {
    background-color: #ededed;
    padding: 20px 20px !important;
    padding-top: 60px !important;
    padding-bottom: 90px !important;
    margin-top: 0px !important;
  }

  .meet-view-work img {
    width: 130px !important;
    margin-top: 50px;
  }

  .meet-view-advance-feature {
    padding: 2px 10px !important;
  }

  .meet-view-advance-feature h2 {
    margin-top: 60px !important;
  }

  .meet-view-advance-feature .advance-feature-image {
    min-height: 350px !important;
  }

  .meet-view-advance-feature .advance-feature-btn {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    margin-left: -10px !important;
  }


  .meet-view-testimonial {
    padding: 60px 10px !important;
  }

  .meet-view-testimonial h1 {
    font-size: 30px !important;
  }

  .advance-feature-image .advance-feature-box {
    width: 85% !important;
    height: 400px !important;
    background-color: #d6d6d6 !important;
  }

  .advance-feature-img {
    width: 100% !important;
    left: 0% !important;
  }

  .meet-view-mail {
    padding-bottom: 0px !important;
  }

  .meet-view-mail h1 {
    font-size: 26px !important;
  }

  .meet-view-mail .main-mobile {
    width: 75% !important;
    margin-top: 30px;
  }

  .meet-view-mail .download-coinblur {
    width: 25% !important;
    left: -2% !important;
    top: 14% !important;
  }

  .meet-view-mail .download-coin {
    width: 20% !important;
    right: 2% !important;
    top: 8% !important;
  }

  .meet-view-mail .download-seccoin {
    width: 20% !important;
    right: 32% !important;
    bottom: 20% !important;
  }

  .clip-shapes {
    height: 880px !important;
  }

  .success-page .ant-card {
    padding: 0px !important;
  }

  .auth-view .ant-card {
    margin: 20px 20px;
    padding: 30px !important;
  }

  .mobile-check .ant-form-item-control {
    margin-left: -0px !important;
  }

  .comment {
    display: none;
  }

  .terms-main {
    margin: 40px 20px 40px 20px !important;
  }

  .privacy-main {
    margin: 40px 20px 40px 20px !important;
  }

  .login-one {
    left: 92px !important;
  }

  .login-two {
    left: 64px !important;
  }

  .reg-one {
    left: 103px !important;
  }

  .reg-two {
    left: 102px !important;
  }

  .set-one {
    left: 91px !important;
  }

  .set-two {
    left: 66px !important;
  }

  .set-three {
    left: 95px !important;
  }

  .set-four {
    left: 117px !important;
  }

  .update-one {
    left: 126px !important;
  }

  .update-two {
    left: 124px !important;
  }

  .change-one {
    left: 138px !important;
  }

  .change-two {
    left: 122px !important;
  }

  .change-three {
    left: 142px !important;
  }

  .meet-view-mail .mobile_img {
    width: 68% !important;
    border-radius: 0% !important;
  }

  .meet-view-mail .prev {
    left: 0px !important;
  }

  .meet-view-mail .next {
    right: 0px !important;
  }

  .meet-view-pricing {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }

  .meet-view-pricing .two {
    padding: 25px 0px 40px 0px !important;
  }

  .toogle_main {
    margin-top: -40px !important;
    justify-content: center !important;
  }

 .toogle_main .active_tab {
    padding: 9.5px 40px 10px 40px !important;
  }

  .pricing-filter{
    margin-top: 0px !important;
    text-align: center;
    margin-bottom: -30px !important;
  }

  .meeting-view-auth-header {
    padding: 10px 20px !important;
  }

  .meeting-view-auth-header ul li {
    padding: 15px 15px !important;
  }

  .meet-view-contact {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .meetingView-faq {
    margin: 20px 10px !important;
  }
}

@media screen and (max-width: 1440px) {
  .secure-class .meet-view-secure h2 {
    font-size: 32px;
    color: #5ba7a9;
    font-weight: 600;
    margin-top: 90px !important;
  }

  .comment {
    font-size: 13px;
    color: grey;
    width: 100%;
  }


  .center-data{
    left: 35% !important;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .success-page .ant-card {
    padding: 10px !important;
  }

  .comment {
    display: none;
  }

  /* your css rules for ipad portrait */
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .success-page .ant-card {
    padding: 10px !important;
  }

  .comment {
    display: none;
  }

  /* your css rules for ipad landscape */
}