.meeting-view{
    background-color: #f0f0f0;
    height: 100vh;
}

.meeting-view-admin-header ul li {
    list-style: none;
    display: inline;
    margin-right: 30px;
    font-size: 14px;
    cursor: pointer;
    color: black;
    font-weight: 500;
}

.meeting-view-admin-header ul li .beta {
    font-size: 12px;
    background-color: var(--theme-color);
    padding: 3px 10px;
    border-radius: 50px;
    color: var(--theme-white);
    margin-left: 5px;
}

.meeting-view-admin-header ul li .anticon {
    font-size: 16px;
    margin-right: 5px;
}

.meeting-view-admin-header ul {
    position: relative;
    top: 20px
}

.meeting-view-admin-header .logo-img {
    width: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.meeting-view-admin-header {
    background-color: var(--theme-white);
    border-color: var(--theme-white);
    /* box-shadow: 0 12px 18px 0 rgb(0 0 0 / 10%); */
    padding: 5px;
    position: fixed !important;
    z-index: 999;
    width: 100%;
}


.meeting-view-admin-header ul li a {
    /* color: rgba(0, 0, 0, 0.5); */
    color: black;
}


.ant-dropdown-menu-title-content {
    color: grey !important;
    margin-left: 10px;
}

.ant-dropdown-menu {
    margin-top: 10px;
    width: 150px;
    position: absolute !important;
    right: 0 !important;
}

.ant-dropdown-menu-title-content .anticon {
    font-size: 15px !important;
    margin-right: 5px;
}



.send-btn {
    text-align: center !important;
    display: inline;
    background-color: var(--theme-color) !important;
    border-radius: 18px !important;
    border-color: var(--theme-border-color);;
    box-shadow: 0 12px 18px 0 rgb(0 0 0 /10%);
    color: var(--theme-white) !important;
    font-size: 15px;
    font-weight: 300;
    height: 35px;
}

.meeting-view-admin-header  .toggle-mobile {
    font-size: 20px;
    margin-top: 25px;
}

.toggle-lists {
    padding: 0px;
}

.toggle-lists li {
    list-style: none;
    margin-top: 20px;
}

.toggle-lists a {
    list-style: none;
    font-size: 15px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
}

.toggle-lists .anticon {
    margin-right: 5px;
}

.toggling {
    list-style: none;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
}

.logout{
    width: 100%;
}

.logout_popup {
    position: absolute;
    top: 35%;
    left: 40%;
}

.logout_popup .ant-modal-content{
    width: 70% !important;
    border-radius: 10px !important;
}

.logout_popup .ant-modal-content .ant-modal-body{
    padding: 20px;
}


.logout_popup .ant-modal-content .ant-modal-body p{
    text-align: center;
    font-size: 1.17em !important;
    font-weight: 600 !important;
    color: black;
    margin-bottom: 25px;
}
.logout_popup .ant-modal-content .ant-modal-body .no_btn{
    background-color: grey;
    border: 1px solid grey;
    border-radius: 4px;
    color: var(--theme-white);
    margin-bottom: 8px!important;
}    

.logout_popup .ant-modal-content .ant-modal-body .yes_btn{
    background-color: var(--theme-color);
    border: 1px solid var(--theme-border-color);
    border-radius: 4px;
    color: var(--theme-white);
    margin-bottom: 0!important;
}

@media only screen and (max-width: 940px) {
    .meeting-view-admin-header ul li{
        font-size: 13px;
    }

    .meeting-view-admin-header .logo-img{
        margin-right: 0px;
    }
  }
  